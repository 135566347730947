/**
 * @description			按格式格式化时间
 * @author				hauner
 * @param fmt   格式字符串，比如：‘yyyy-MM-dd’
 * @param date  要格式化的时间变量，可以是任何能够转换成时间的变量
 * @data				2022-04-07
 */
export function formatTime(fmt = 'yyyy-MM-dd', date = new Date()) {
  let ret
  date = new Date(date)
  if (!(date instanceof Date)) throw Error(`${date.toString}不是一个正确的时间`)
  const opt = {
    'y+': date.getFullYear().toString(), // 年
    'M+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'h+': date.getHours().toString(), // 时
    'm+': date.getMinutes().toString(), // 分
    's+': date.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  }
  for (let k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt)
    if (ret) {
      fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
    }
  }
  return fmt
}
// 是否为空
export const isEmpty = value => {
  if (value === null || value === undefined) return true

  return false
}
/**
 * @description			补零函数
 * @author				hauner
 * @param num   要补零的正整数数字
 * @param count 补零之后的为数
 * @data				2022-03-29
 */
export function fillZero(num, count = 2) {
  if (isNaN(num)) num = ''
  if (isNaN(count)) count = 2
  if (count < 0) count = -count
  if (`${num}`.length > count) return num
  return `${new Array(count).join('0')}${num}`.slice(-count)
}
export default {
  formatTime,
  isEmpty,
  fillZero
}
